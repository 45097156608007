<template>
  <section class="m-order m-scroll" ref="home" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <m-header title="My Order" >
      <span class="top-right" slot="right" @click="showFilters = true">Filters</span>
    </m-header>

    <!-- 订单详情列表 -->
    <order-list :data="orderList"></order-list>
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
    <p class="search-no" v-if='finished'>No More Order</p>  

    <!-- 筛选弹窗 -->
    <van-popup v-model="showFilters" position="bottom" class="fliters-box">
        <IndexHeader title="Fliters" @close="showFilters = false" :isBack="false">
            <span slot="right" class="pop-right" @click="resetFilters">Clear</span>
        </IndexHeader>
        <div class="fliter-wrap">
          <div class="filter-box">
            <div class="filter-title">Order Type</div>
            <div class="filter-item" v-for="(item, index) in orderType" :key="index" :class="{'filterActive' : typeActive == index}" @click="typeActive = index">
              <img src="@/assets/payment/select.png" alt="">
              <img src="@/assets/payment/active.png" alt="">
              <span>{{item}}</span>
            </div>
          </div>
          <div class="filter-box">
            <div class="filter-title">Time screening</div>
            <div class="filter-item" v-for="(item, index) in timeList" :key="index" :class="{'filterActive' : timeActive == index}" @click="timeActive = index">
              <img src="@/assets/payment/select.png" alt="">
              <img src="@/assets/payment/active.png" alt="">
              <span>{{item}}</span>
            </div>
          </div>
        </div>
        <div class="apply-set" @click="reGetOrder">APPLY</div>
    </van-popup>

    <!-- 游客查看 -->
    <div class="guest-order" v-if="guest">
      <div class="view-button" @click="$router.push('/en/search_order')">Guest check order</div>
    </div>
  </section>
</template>

<script>
import MHeader from '@/components/en/tab-header.vue'
import IndexHeader from '@/components/en/m-header.vue'
import OrderList from '@/components/en/order-list.vue'

import { getOrder, getAftersale } from '@/api/zh/order.js'
import { isGuest } from '@/api/zh/mine.js'
export default {
  name:'Index',
  components:{ MHeader, OrderList, IndexHeader },
  data(){
    return {
      showFilters: false,   // 筛选面板显示
      orderType  : ['All', 'To pay for the order', 'Unshipped orders', 'Shipped orders', 'Refund after sale'],
      timeList   : ['All', 'Past 30 days', 'Past 3 months', 'Past 6 months'],
      typeActive : 0,
      timeActive : 0,
      orderList  : [],
      page       : 1,
      islock     : false,
      loading    : false,
      finished   : false,
      guest      : false
    }
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.finished){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getOrderHandle()
        }
      }
    },
    resetFilters() {
        // 重置筛选条件
        this.typeActive  = 0
        this.timeActive  = 0
    },
    getOrderHandle() {
      // 获取订单
      this.islock = true
      let params = {
        month: this.timeActive == 0 ? null : (this.timeActive + 1) * this.timeActive / 2,
        status: this.typeActive,
        page: this.page
      }
      getOrder(params).then(res => {
        if(res) {
          this.orderList = [...this.orderList, ...res.data.data]
        }else {
          this.finished = true
        }
      }).finally(() => {
        this.$store.commit('unload')
        this.islock = false
        this.loading = false
      })
    },
    getAftersaleHandle() {
      // 获取售后列表
      let params = {
        month: this.month,
        status: this.typeActive,
        page: this.page
      }
      getAftersale(params).then(res => {
        if(res) {
          if(this.page == 1) {
            this.orderList = []
          }
          this.orderList = [...this.orderList, ...res.data.data]
          this.loading = false
        }else {
          this.finished = true
        }
      })
    },
    reGetOrder() {
      // 筛选
      this.page      = 1
      this.islock    = false
      this.finished  = false
      this.orderList = []

      if(this.typeActive == 4) {
        this.getAftersaleHandle()
      } else {
        this.getOrderHandle()
      }
      
      this.showFilters = false
    },

    isGuestHandle() {
      // 是否市游客
      isGuest().then(res => {
        if(res) {
          this.guest = res.status == 1 ? true : false
        }
      })
    }
  },
  created() {
    this.$store.commit('load')
    this.getOrderHandle()
    this.isGuestHandle()
  },
  beforeRouteEnter (to, from, next) {
    if(from.path == '/en/paystatus' || from.path == '/en/account') {
      next(vm => {
        vm.resetFilters()
        vm.orderList = []
        vm.page = 1
        vm.islock = false
        vm.getOrderHandle()
      })
    } else {
      next()
    }
  }
}
</script>

<style lang="less" scoped>
@import './list.less';
.guest-order{position: fixed;bottom:0;left:0;width:100%;height:50px;background-color: #fff;border-top:1px soild #f1f1f1;z-index: 99;;
  .view-button{margin:7px auto;width:300px;height:36px;background: linear-gradient(315deg,#F03C18,#F06448);
  border-radius: 36px;text-align: center;line-height: 36px;color:#fff;font-size: 16px;}
}
.top-right{font-size: 12px;font-weight:600;color: #2A65BA !important;}
.search-no{line-height:80px;text-align:center;color:#888;font-size:14px}
.fliters-box{width: 100%;height: 500px;}
.pop-right{color:#2A65BA !important; font-size:3.2vw;font-weight:600}
</style>
