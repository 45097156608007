<template>
  <div class="order-item-all">
    <div class="store-item m-padding" v-for="(item, index) in data" :key="index">
      <div class="store-title van-hairline--bottom">
          <div class="merchant">
            <img src="@/assets/order/mooby.png" alt="merchant" v-if="item.shops_id == 1">
            <img src="@/assets/order/shop.png" alt="merchant" v-if="item.shops_id != 1">
            <p class="van-ellipsis">
               {{item.shops_title}}
              <span>({{(item.order_goods || item.order_goods_all).length}} {{(item.order_goods || item.order_goods_all).length == 1 ? 'item' : 'items'}})</span>
              <span>{{item.created_at}}</span>
            </p>
          </div>
          <div class="isSelfpick" @click="goToDetail(item)">
              View Order
          </div>
      </div>
      <div class="goods-list">
          <div v-for="(it, ind) in (item.order_goods || item.order_goods_all)" :key="ind" right-width="145px"
          @click="goToDetail(item)">
              <div class="goods-item">
                  <img :src="it.goods_sku_pic_url?it.goods_sku_pic_url:'/moren.png'" alt="" class="goods-img">
                  <div class="goods-right">
                      <p class="van-multi-ellipsis--l2">
                        <span class="m-mooby" style="width:55px" v-if="it.is_presale == 1">Pre-Sale</span>
                        {{it.goods_ename}}
                      </p>
                      <div class="sku" v-if="it.sku_ename">
                          {{it.sku_ename.split(',').join(' | ')}}
                      </div>
                      <div class="total">
                          <span>{{item.order_logistics.country == 507 ? 'US$':'C$'}}{{it.goods_price}}</span>
                          ×{{it.goods_number || it.after_sales_number}}
                      </div>
                      <div class="order-status">{{item.order_status_title}}</div>
                      <!-- <div class="order-status" v-if="status == 0">Unpaid</div>
                      <div class="order-status" v-if="status == 1">Not yet shipped</div>
                      <div class="order-status" v-if="status == 2">Shipped</div>
                      <div class="order-status" v-if="status == 3">Received</div> -->
                  </div>
              </div>
          </div>
      </div>
      <div class="order-tools">
        <div class="price" v-if="item.order_status != 4">Total：<span>{{item.order_logistics.country == 507 ? 'US$':'C$'}}</span><span>{{item.order_total_amount}}</span></div>
        <!-- <div class="price" v-else>售后状态：<span></span><span>{{item.audit_status_title}}</span></div> -->
        <div class="btns">
          <span class="btn" @click.prevent="onCancel(item)" v-if="item.order_status == 0">Cancle Order</span>
          <span class="btn-acitve" @click.prevent="onPay(item)" v-if="item.order_status == 0">Pay Now</span>
          <router-link :to="`/en/aftersale/select/${item.id}`" class="btn-acitve"
          v-if="item.order_status == 2 || item.order_status == 1 || item.order_status == 3">After Sales</router-link>
          <span class="btn-acitve" @click.prevent="onComment(item.id)" v-if="item.order_status == 3  && item.evaluate_status == 0">Comments</span>
          <!-- <span class="btn-acitve" @click.prevent="onPay()" v-if="item.order_status == 4">Buy again</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cancelOrder } from '@/api/zh/order.js'
export default {
  props: ['data'],
  data(){
    return {
      status: 0
    }
  },
  created(){

  },
  methods: {
    goToDetail(item) {
      if(item.order_id) {
        sessionStorage.setItem('aftersale', JSON.stringify(item))
      }
      this.$router.push(`/en/${item.order_id ? 'aftersale' : 'order'}/detail/${item.id}`)
    },
    onPay(data) {
      // 继续支付
      localStorage.setItem('order', JSON.stringify(data))
      if(data.order_logistics && data.order_logistics.country == 507) {
        this.$router.replace({path: '/en/usPay', query: {id: data.id, delivery_method: data.delivery_method, status: 1}})
      } else {
        this.$router.replace({path: '/en/pay', query: {id: data.id, delivery_method: data.delivery_method, status: 1}})
      }
      if(data.order_goods[0].goods_id == '3751') {
        localStorage.setItem('bargain', 1)
      }
    },
    onCancel(item) {
      // 取消订单
      cancelOrder(item.id).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg })
          item.order_status = 4
          item.order_status_title = 'Transaction closed'
        }
      })
    },
    onComment(id) {
      this.$router.push({path: '/en/comment/submit', query: {id: id}})
    }
  }
}
</script>

<style lang="less" scoped>
.order-item-all {background-color: #F5F9FF;
  .store-item{
    margin-bottom:14px;
    width:100%;
    background-color: #fff;
    border-radius: 4px;
    .store-title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      height:64px;
      font-size:16px;
      font-weight:500;
      color:rgba(17,35,55,1);
      .merchant {
        height: 64px;
        display: flex;
        align-items: center;
        flex: 1;
        &>img {
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-right: 5px;
          border-radius: 50%;
          box-shadow: 0px 1px 4px rgba(102, 102, 102, 0.1); 
        }
        &>p {
          font-size:16px;
          font-weight:500;
          width: 100%;
          color:rgba(12,2,29,1);
          &>span {
            display: inline-block;
            font-size: 12px;
          }
          &>span:nth-child(2) {
            margin-top: 2px;
            display: block;
            color: #666;
            font-size: 12px;
          }
        }
      }
      .isSelfpick{
        font-size:14px;
        font-weight:400;
        color:#3284FA;
        height: 26px;
        width: 89px;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
        border:1px solid #3284FA;
      } 
    }
    .goods-list{width:100%;
        .goods-item{width:100%;display: flex;margin-top:10px;margin-bottom:15px;
            .goods-select{width:24px;height:24px;margin:30px 10px 0 0;
                &:nth-of-type(1){display: inline-block}
                &:nth-of-type(2){display: none}
            }
            .goods-img{width:80px;height:80px;object-fit: cover;border-radius: 4px}
            .goods-right{flex: 1;height:100%;margin-left:15px;width:0;
                >p{
                    font-size:14px;
                    
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    line-height:18px;
                    width:100%;
                }
                .sku{
                    display: inline-block;
                    margin-top:8px;
                    padding:0px 6px;
                    border-radius:3px;
                    font-size:12px;font-weight:400;
                    color:rgba(102,102,102,1);
                    >img{width:20px;height:20px;vertical-align: -5px}
                }
                .total{display: flex;margin-top:10px;
                    >span{
                        display: block;
                        flex: 1;
                        font-size:14px;
                        
                        font-weight:500;
                        color:rgba(0,0,0,1);
                        line-height: 22px
                    }
                }
            }
            .order-status{
              font-size: 16px;
              
              font-weight: 500;
              color: #0854BF;
              margin-top:4px;
            }
        }
        .like-img-but{width:50px;height:50px;margin: 20px 0 0 20px}
    }
    .order-tools{
      text-align: right;
      border-top:1px solid #f1f1f1;
      line-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      justify-items: flex-end;
      .price {
        font-size:14px;
        font-weight:400;
        color:rgba(51,51,51,1);
        &>span:nth-of-type(1){
          font-size: 10px;
          color: #ED2A24;
        }
        &>span:nth-of-type(2){
          color: #ED2A24;
        }
      }
      .btns {
        .btn-acitve {
          padding: 5px 12px;
          font-size: 14px;
          color: #ED2A24;
          border: 1px solid #ED2A24;
          border-radius: 35px;
          margin-left: 5px;
        }
        // .btn-acitve + .btn-acitve, .btn-acitve + .btn {
        //   margin-left: 5px;
        // }
        .btn {
          padding: 5px 12px;
          font-size: 14px;
          color: #333333;
          border: 1px solid #979797;
          border-radius: 35px;
        }
      }
    }
  }
}
</style>